import React from 'react';

const ModalMenuPrimary = () => {
    return (
        <nav className="menu-primary">
            <ul className="nav flex-column">
                <li className="nav-item">
                    <a title="O nas" href={ process.env.PUBLIC_URL + "/#o-nas" }>O nas</a>
                </li>

                <li className="nav-item">
                    <a title="Cennik" href={ process.env.PUBLIC_URL + "/#cennik" }>Cennik</a>
                </li>


                {/*<li className="nav-item">*/}
                {/*    <a title="Our Teams" href={ process.env.PUBLIC_URL + "/#our-team" }>Our Team</a>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                {/*    <a title="Testimonials" href={ process.env.PUBLIC_URL + "/#testimonials" }>Testimonials</a>*/}
                {/*</li>*/}

                <li className={ "nav-item " + ( window.location.pathname.includes( "/co-nowego/" ) || window.location.pathname.includes( "/news-single-page" )? "current-nav-item" : "" ) }>
                    <a title="Co nowego" href={ process.env.PUBLIC_URL + "/co-nowego/" }>co nowego?</a>
                </li>

                <li className="nav-item">
                    <a title="Kontakt" href={ process.env.PUBLIC_URL + "/#kontakt" }>Kontakt</a>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuPrimary;
