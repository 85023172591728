import React from 'react';
import _ from 'lodash';

import tagsUtil from "../../utils/tagsUtil";
import RecentEntries from '../../data/news/newsItems';

const WidgetTagCloud = () => {
    const tags = distinguishTags();
    return (
        <div className="widget widget_tag_cloud">
            <h6 className="widget-title">Kategorie</h6>
            <div className="tagcloud">
                { tags && tags.map( ( tag, key ) => {
                    return (
                        <a key={key} title={tag.title} href={ tag.link } className="tag-cloud-link">{ tag.name }</a>
                    );
                })}
            </div>
        </div>
    );
};

function distinguishTags() {
    return _.uniq(RecentEntries.flatMap(re => re.tags))
        .sort((a, b) => a > b ? 1 : -1)
        .map(tag => tagsUtil.asTagObject(tag));
}

export default WidgetTagCloud;
