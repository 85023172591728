import React from 'react';
import moment from 'moment'
import 'moment/locale/pl'

const  NewsImg = ( {imgSource, imgAlt, date}) => {
    return (
        <div className="post-img">
            <div className="img object-fit">
                <div className="object-fit-cover">
                    <img src={ process.env.PUBLIC_URL + "/assets/img/news/1050x600/" + imgSource } alt={ imgAlt } />
                </div>
            </div>

            <div className="post-date">
                <div className="post-date-0">
                    <p>
                        <span className="post-date-number">{ moment(date).format('DD') }</span>
                        { moment(date).format('MMM') }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NewsImg;
