import React from 'react';
import AliceCarousel from 'react-alice-carousel';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const items = [
    <div className="homeAboutImgItem" data-value="1"><img src="/assets/img/gallery/1.jpg" alt="miejsca parkingowe dla pacjentów przychodni"/></div>,
    <div className="homeAboutImgItem" data-value="2"><img src="/assets/img/gallery/2.jpg" alt="poczekalnia"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/11.jpg" alt="leczenie dzieci"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/9.jpg" alt="leczenie pacjeta dorosłego"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/14.jpg" alt="wnętrze gabinetu"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/3.jpg" alt="recepcja"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/12.jpg" alt="edukacja dzeici"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/8.jpg" alt="koncik dla najmłodszych"/></div>,
    <div className="homeAboutImgItem" data-value="4"><img src="/assets/img/gallery/10.jpg" alt="światło utwardzanei"/></div>,
    <div className="homeAboutImgItem" data-value="1"><img src="/assets/img/gallery/4.jpg" alt="wnętrze poczekalni"/></div>,
];

const Carousel = () => (
    <AliceCarousel
        autoPlay
        autoPlayStrategy="default"
        autoPlayInterval={3000}
        animationDuration={1000}
        animationType="fadeout"
        infinite
        mouseTrackingnone
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
        disableButtonsControls
    />
);

const HomeAboutImg = () => {
    return (
        <div id="about-img" className="spacer p-top-sm">
            <div className="wrapper">
                <div className="title text-max-width">
                    <h2>Galeria zdjęć</h2>
                </div>
            </div>
            <div className="img-size-1">
                <div className="img object-fit">
                    <div className="object-fit-cover">
                        <Carousel/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutImg;
