import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleConsent from '../blocks/page-title/PageTitleConsent';
import BackToHome from '../components/button/BackToHome';

const Consent = () => {
    // document.body.classList.add( 'error404' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Polityka prywatności</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="polityka prywatności" />
                <meta name="keywords" content="polityka prywatnosci" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleConsent />

                <div id="page-content" className="spacer p-top-lg">
                    <div className="wrapper">
                        <div className="content">
                            <p>Dziękujemy za odwiedzenie <strong>przychodniaNaPanskiej.pl</strong>! Dbamy o Twoją prywatność i pragniemy, abyś był informowany o tym, jakie dane zbieramy i w jaki sposób je wykorzystujemy. Niniejsza Polityka Prywatności wyjaśnia nasze praktyki w zakresie zbierania i przetwarzania danych.</p>

                            <h2>Dane, które zbieramy</h2>

                            <ul>
                                <li>
                                    <strong>Dane analityczne:</strong> Korzystamy z Google Analytics, aby analizować ruch na naszej stronie. Google Analytics zbiera informacje o odwiedzanych stronach, czasie spędzonym na stronie, źródłach ruchu oraz innych danych statystycznych. Nie zbieramy jednak informacji osobistych, które pozwoliłyby na identyfikację użytkownika.
                                </li>
                                <li>
                                    <strong>Dane lokalizacyjne:</strong> Używamy Google Maps do wyświetlania map i lokalizacji. Google Maps może zbierać informacje na temat Twojej lokalizacji, jeśli zezwalasz na to w ustawieniach przeglądarki.
                                </li>
                            </ul>

                            <h2>Jak chronimy Twoje dane</h2>

                            <p>Stosujemy najlepsze praktyki oraz technologie zabezpieczające w celu ochrony Twoich danych i zapobiegania nieautoryzowanemu dostępowi do nich. Jednak żadna metoda przechowywania danych w Internecie lub metoda transmisji elektronicznej nie jest w 100% bezpieczna. Dlatego, choć dążymy do maksymalnego zabezpieczenia Twoich danych, nie możemy zagwarantować ich pełnego bezpieczeństwa.</p>

                            <h2>Linki do innych stron</h2>

                            <p>Nasza strona może zawierać linki do innych stron internetowych, których polityki prywatności mogą różnić się od naszej. Jeśli korzystasz z tych linków, zachęcamy do zapoznania się z polityką prywatności każdej z odwiedzanych stron.</p>

                            <h2>Kontakt</h2>

                            <p>Jeśli masz pytania dotyczące naszej polityki prywatności, skontaktuj się z nami pod adresem: <a href="mailto:kontakt@przychodnianapanskiej.pl">kontakt@przychodnianapanskiej.pl</a>.</p>

                            <BackToHome />
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Consent;
