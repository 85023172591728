import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags'

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleNews from '../blocks/page-title/PageTitleNews';
import NewsImg from '../blocks/news/NewsImg';
// import NewsMeta from '../blocks/news/NewsMeta';
import NewsDescription from '../blocks/news/NewsDescription';
import NewsTags from '../blocks/news/NewsTags';
// import NewsComments from '../blocks/news/NewsComments';
import Widget from '../blocks/widget/Widget';

import NewsItemsData from '../data/news/newsItems';

const NewsSinglePost = () => {
    document.body.classList.add( 'single-post' )
    const { pageName } = useParams();
    const newsItem = getByTitle(pageName);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>{ newsItem.meta.title }</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="{ newsItem.meta.description }" />
                <meta name="keywords" content="{ newsItem.meta.keywords }" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleNews pageTitle={newsItem.title} />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div className="row gutter-width-md">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 single-content">
                                        <NewsImg imgSource={ newsItem.imgSource1050x600 }
                                                 imgAlt={ newsItem.imgAlt }
                                                 date={ newsItem.date }
                                        />

                                        {/*<NewsMeta />*/}

                                        {/*<div className="title">*/}
                                        {/*    <h2>Do You Need to Prepare before Going to the Dentist?</h2>*/}
                                        {/*</div>*/}

                                        <NewsDescription content={newsItem.content}/>

                                        <NewsTags tags={newsItem.tags}/>

                                        {/*<NewsComments />*/}
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <Widget />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );

    function getByTitle(pageTitle) {
        return NewsItemsData.find((element) => {
            return element.link === '/co-nowego/' + pageTitle;
        })
    }
};

export default NewsSinglePost;
