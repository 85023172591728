import React from 'react';
import { Link } from 'react-scroll';

const HomeModalMenu = ( props ) => {
    return (
        <nav className="menu-primary">
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link onClick={ props.action } to="o-nas" title="O nas" href="#o-nas" smooth={true} duration={700}>O nas</Link>
                </li>

                <li className="nav-item">
                    <Link onClick={ props.action } to="cennik" title="Cennik" href="#cennik" smooth={true} duration={700}>Cennik</Link>
                </li>

                {/*<li className="nav-item">*/}
                {/*    <Link onClick={ props.action } to="our-team" title="Our Teams" href="#our-team" smooth={true} duration={700}>Our Team</Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                {/*    <Link onClick={ props.action } to="testimonials" title="Testimonials" href="#testimonials" smooth={true} duration={700}>Testimonials</Link>*/}
                {/*</li>*/}

                <li className="nav-item">
                    <Link onClick={ props.action } to="co-nowego" title="Co nowego?" href="#co-nowego" smooth={true} duration={700}>Co nowego</Link>
                </li>

                <li className="nav-item">
                    <Link onClick={ props.action } to="kontakt" title="Kontakt" href="#kontakt" smooth={true} duration={700}>Kontakt</Link>
                </li>
            </ul>
        </nav>
    );
};

export default HomeModalMenu;
