import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from './pages/Home';
import News from './pages/News';
import NewsSinglePost from './pages/NewsSinglePost';
// import SearchResults from './pages/SearchResults';
import page404 from './pages/404';
import Consent from './pages/consent';
import UserInterface from './pages/UserInterface';
import ReactGA from "react-ga4";

const history = createBrowserHistory();

const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-RBTF1PX0YY';

ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);

history.listen((location) => {
    ReactGA.send('pageview', { page_path: location.pathname + location.search });
});

function App() {
    useEffect(() => {
        ReactGA.send('pageview', { page_path: window.location.pathname + window.location.search });
    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route exact path={ `${ process.env.PUBLIC_URL + "/" }` } component={ Home } />
                <Route exact path={ `${ process.env.PUBLIC_URL + "/co-nowego/" }` } component={ News } />
                <Route exact path={ `${ process.env.PUBLIC_URL + "/co-nowego/:pageName" }` } component={ NewsSinglePost } />
                <Route exact path={ `${ process.env.PUBLIC_URL + "/polityka-prywatnosci" }` } component={ Consent } />
                {/*<Route exact path={ `${ process.env.PUBLIC_URL + "/search-results" }` } component={ SearchResults } />*/}
                {/*<Route exact path={ `${ process.env.PUBLIC_URL + "/ui" }` } component={ UserInterface } />*/}
                <Route exact component={ page404 } />
            </Switch>
        </Router>
    );
}

export default App;
