import React from 'react';
import ReactHtmlParser from "react-html-parser";

const NewsDescription = ({ content }) => {
    return (
        <div className="description clearfix">
            { ReactHtmlParser(content) }
        </div>
    );
};

export default NewsDescription;
