const tagsUtil = {
    escapeTagName(tagName) {
        return tagName.replaceAll(' ', '-');
    },

    asTagObject(tagName) {
        return ({
            'name': tagName,
            'title': tagName,
            'paramName': tagsUtil.escapeTagName(tagName),
            'link': '/co-nowego/?kategoria=' + tagsUtil.escapeTagName(tagName)
        });
    }

}

export default tagsUtil;
