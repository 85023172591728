import React from 'react';

// import ContactForm from '../../components/form/ContactForm';

const ContactsUs = () => {
    return (
        <section id="kontakt" className="block bg-gray-light spacer p-top-xl p-bottom-md">
            <div className="wrapper">
                <div className="title text-max-width-2">
                    <h2>Jak dojechać?</h2>
                </div>


                <div className="row gutter-width-md with-pb-md">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h5>Samochodem</h5>
                        <p>Przychodnia udostępnia dla Pacjentów 8 dedykowanych miejsc postojowych znajdujących się tuż przy wejściu do budynku.</p>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h5>Komunikacją miejską</h5>
                        <ul>
                            <li>Przystanek na ul. Dębickiej(przy Delikatesach Centrum) "Dębicka - Dom Kultury" - obsługuje linie: 16, 33</li>
                            <li>Pętla autobusowa "Baligrodzka", skrzyżowanie ul. Iwonickiej z ul. Pańską - obsługuje linie: 2, 27, 47</li>
                        </ul>
                    </div>
                </div>

                <p className="spacer p-top-sm">Przychodnia znajduje się na ulicy <b>Pańskiej 19</b> w Rzeszowie, tuż przy skrzyżowaniu z ulicą Iwonicką w sąsiedztwie publicznego przedszkola "Kotek Psotek".</p>

                {/*<div className="description text-max-width-2">*/}
                {/*    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p>*/}
                {/*</div>*/}

                {/*<div className="contact-form-shortcode spacer p-top-lg">*/}
                {/*    <ContactForm />*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default ContactsUs;
