import React from 'react';

import BackToTop from '../../components/back-to-top/BackToTop';

import Copyright from '../footer/Copyright';
import FooterMenu from '../footer/FooterMenu';
import FooterLogo from '../footer/FooterLogo';
import CookieConsent from 'react-cookie-consent';

const Footer = () => {
    return (
        <footer id="footer" className="site-footer">
            <div className="wrapper">
                <BackToTop />
                <div className="footer">
                    <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                        <div className="align-self-center">
                            <FooterLogo />
                        </div>

                        <div className="align-self-center">
                            <Copyright />
                        </div>

                        <div className="align-self-center">
                           <FooterMenu />
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent location="bottom"
               debug={false}
               buttonText="Ok, rozumiem"
               buttonClasses="btn btn-secondary active"
            >
                Jak wszyscy stosujemy pliki cookies. Jeśli nie blokujesz tych plików to zakładamy, że zgadzasz się na ich użycie oraz zapisanie w pamięci przeglądarki.
                &nbsp;
                <a href="/polityka-prywatnosci" className="alert-link">Polityka prywatności</a>
            </CookieConsent>
        </footer>
    );
};

export default Footer;
