import React, {Component, Fragment} from 'react';
import MetaTags from 'react-meta-tags';
import moment from 'moment'

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleNews from '../blocks/page-title/PageTitleNews';
import Widget from '../blocks/widget/Widget';
import tagsUtil from "../utils/tagsUtil";

import NewsItemsData from '../data/news/newsItems';

class News extends Component {

    static ITEMS_PER_PAGE = 4;

    constructor( props ) {
        super( props );

        let requestedCategory = new URLSearchParams(props.location.search).get("kategoria");
        let requestedPage = parseInt(new URLSearchParams(props.location.search).get("strona"));
        this.state = {
            selectedCategory: requestedCategory,
            page: requestedPage && requestedPage > 0 ? requestedPage - 1 : 0,
            pages: 0,
            firstDisabled: true,
            lastDisabled: true,
            news: []
        };
    }

    componentDidMount() {
        this.filterNews();
    }

    getSelectedCategoryDisplayName = () => {
        return NewsItemsData.flatMap(ni => ni.tags).find(tag => tagsUtil.escapeTagName(tag) === this.state.selectedCategory);
    }

    removeFilter = () => {
        this.setState({
            selectedCategory: null
        }, () => {
            this.filterNews();
        });

    }

    filterNews = () => {
        let filteredNews = this.state.selectedCategory == null
            ? NewsItemsData
            : NewsItemsData.filter(n => n.tags.find(tag => tagsUtil.escapeTagName(tag) === this.state.selectedCategory) != null);
        let pages = Math.floor(filteredNews.length / News.ITEMS_PER_PAGE) + (filteredNews.length % News.ITEMS_PER_PAGE > 0 ? 1 : 0);

        this.setState((state) => ({
            page: state.page > pages ? 0 : state.page,
            news: filteredNews.slice((state.page > pages ? 0 : state.page) * News.ITEMS_PER_PAGE, (state.page + 1) * News.ITEMS_PER_PAGE),
            pages: pages,
            firstDisabled: state.page <= 0,
            lastDisabled: state.page >= pages - 1
        }));

        console.log('==================================================');
        console.log('page:' + this.state.page);
        console.log('pages:' + this.state.pages);
        console.log('news.length:' + this.state.news.length);
        console.log('firstDisabled:' + this.state.firstDisabled)
        console.log('lastDisabled:' + this.state.lastDisabled)
        console.log('itemsPerPage:' + this.ITEMS_PER_PAGE)
        console.log('selectedCategory:' + this.state.selectedCategory)
    }

    showPage = (newIndex) => {
        this.setState({
            page: newIndex
        }, () => {
            this.filterNews();
        });
    }

    showNext = () => {
        let newPage = this.state.page + 1;
        this.setState({
            page: newPage
        }, () => {
            this.filterNews();
        });
    }

    showPrev = () => {
        let newPage = this.state.page - 1;
        this.setState({
            page: newPage
        }, () => {
            this.filterNews();
        });
    }

    render() {
        return (
            <Fragment>
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>Informacje dla pacjentów</title>

                    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="Informacje dla Pacjentów. " />
                    <meta name="keywords" content="informacje, blog, nowości" />
                    <meta name="robots" content="index, follow, noodp" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="google" content="notranslate" />
                    <meta name="format-detection" content="telephone=no" />
                </MetaTags>

                <Loading />

                <Header />

                <main id="main" className="site-main">
                    <PageTitleNews pageTitle="Co nowego w Przychodni na Pańskiej?" pageDescription="W tym dziele dzielimy się z Państwem wiedzą i publikujemy informacje z życia Przychodni na Pańskiej."/>

                    <div id="page-content" className="spacer p-top-lg">
                        <div className="wrapper">
                            <div className="content">
                                <div id="blog">
                                    <div className="row gutter-width-md">
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12" id="newsList">

                                            <div className={(this.state.selectedCategory == null ? 'invisible ' : 'visible ') + 'p-3 mb-5 bg-info text-white align-middle' }>
                                                <span className="col-sm-10">Przeglądasz posty tylko z kategorii:</span>
                                                <span className="badge badge-secondary" title="Usuń ten filtr">
                                                    { this.getSelectedCategoryDisplayName() }
                                                    <i className="far fa-times-circle ml-md-2" onClick={ this.removeFilter }></i>
                                                </span>
                                            </div>

                                            <div className="row gutter-width-md with-pb-lg">
                                                { this.state.news && this.state.news.map ( ( item, key ) => {
                                                    return(
                                                        <div key={ key } className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div className="card card-post">
                                                                <div className="card-top position-relative">
                                                                    <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>
                                                                        <div className="img object-fit overflow-hidden">
                                                                            <div className="object-fit-cover transform-scale-h">
                                                                                <img className="card-top-img" src={ item.imgSrc } alt={ item.title } />
                                                                            </div>
                                                                        </div>
                                                                    </a>

                                                                    <div className="post-date">
                                                                        <div className="post-date-0">
                                                                            <p>
                                                                                <span className="post-date-number">{ moment(item.date).format('DD') }</span>
                                                                                { moment(item.date).format('MMM') }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <h5 className="card-title">
                                                                        <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                                                                    </h5>

                                                                    <p className="card-text">{ item.description }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <ul className="pagination justify-content-center">
                                                <li className={ (this.state.firstDisabled ? 'disabled' : '') + ' page-item' }>
                                                    <a className="page-link scroll-to-id" href="#title"  onClick={ this.showPrev }
                                                       aria-disabled={ this.state.firstDisabled }>Poprzednia</a>
                                                </li>
                                                {[...Array(this.state.pages)].map((elementInArray, index) => (
                                                            <li key={ index } className={ 'page-item' + (this.state.page === index ? ' active' : '') }>
                                                                <a className="page-link scroll-to-id" href="#title"
                                                                   onClick={ () => this.showPage( index ) }>{ index  + 1 }</a>
                                                            </li>
                                                        )
                                                )}
                                                <li className={ (this.state.lastDisabled ? 'disabled' : '') + ' page-item' }>
                                                    <a className="page-link scroll-to-id" href="#title" onClick={this.showNext}
                                                       aria-disabled={ this.state.lastDisabled }>Następna</a>
                                                </li>
                                            </ul>

                                        </div>

                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <Widget />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />
            </Fragment>
        );
    };
};

export default News;
