import React from 'react';
import scriptLoader from 'react-async-script-loader';
import GoogleMapsStyle from './googleMapsStyle';

class Maps extends React.Component {
    constructor( props ) {
        super( props );
        this.mapRef = React.createRef();
        this.map    = null;
    };

    UNSAFE_componentWillReceiveProps( { isScriptLoaded, isScriptLoadSucceed } ) {
        let map;
        let lat = 50.04943996558283;
        let lng = 21.946347707902056;
        const address_latlng = new window.google.maps.LatLng( lat, lng );

        const infoWindowHtml =
        '<div style="display:inline-block;width:100%;font-family:Poppins-Regular">' +
            '<div style="padding:1rem;float:left">'+
                '<img style="max-width:10rem;max-height:10rem;" src="/assets/img/maps/wejscie.jpg" />' +
            '</div>' +
            '<div style="padding:1rem; float: left; font-size: 1.3rem">'+
                '<div style="margin-bottom: 0.5rem"> Przychodnia na Pańskiej </div>' +
                '<div><b>ul. Pańska 19</b></div>' +
                '<div style="margin-bottom: 0.5rem">Rzeszów</div>' +
                '<div>' +
                    '<i class="fas fa-phone-alt" style="margin-right: 0.5rem;font-size:1rem"></i>' +
                    '<span>' +
                        '<a href="tel:+48509669667">509 669 667</a>' +
                    '</span>' +
                '</div>' +
            '</div>' +
        '<div>';

        if ( isScriptLoadSucceed ) {
            map = new window.google.maps.Map( this.mapRef.current, {
                center: address_latlng,
                zoom: 15,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: GoogleMapsStyle
            } );

            const infowindow = new window.google.maps.InfoWindow({
                content: infoWindowHtml
            });

            let marker = new window.google.maps.Marker( {
                position: { lat: lat, lng: lng },
                map: map
            } );

            marker.addListener("click", () => {
                infowindow.open(map, marker);
            });

            infowindow.open(map, marker);

        } else this.props.onError();
    };

    render() {
        return (
            <div id="google-maps" class="footer-no-border" ref={ this.mapRef }></div>
        );
    };
};

export default scriptLoader( ['https://maps.googleapis.com/maps/api/js?key=AIzaSyAGD8BHvtYqK85tbf28eljnASgmSsvECQs'] )( Maps );
