import React from 'react';
import tagsUtil from "../../utils/tagsUtil";

const NewsTags = ({ tags }) => {
    let tagsObj = tags
        .map(t => tagsUtil.asTagObject(t))
        .sort((a, b) => a > b ? 1 : -1);

    return (
        <div className="tags">
            <p>
                { tagsObj && tagsObj.map( ( tag, key ) => {
                    return (
                        <a key={ key } title={ tag.title } href={ process.env.PUBLIC_URL + tag.link }>{ tag.name }</a>
                    );
                })}
            </p>
        </div>
    );
};

export default NewsTags;
