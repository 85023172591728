import React from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img with-information-items" style={ { backgroundImage: `url('assets/img/main.jpg')` } }>
            <div className="wrapper d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">Twoje Zdrowie w Dobrych Rękach!</h1>
                    </div>

                    <div className="description spacer p-top-lg text-max-width">
                        <p>W Przychodni na Pańskiej stawiamy pacjenta w centrum uwagi. Nasza misja to zapewnienie kompleksowej, indywidualnej i przyjaznej opieki stomatologicznej. Dzięki doświadczonemu zespołowi specjalistów, możemy zagwarantować, że Twoje potrzeby zostaną zrozumiane i spełnione w komfortowej atmosferze.
                        </p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link to="o-nas" href="#o-nas" className="btn btn-primary scroll-to-id">Czytaj więcej</Link>
                    </div>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
