import React from 'react';
import moment from 'moment'
import 'moment/locale/pl'

import HomeItems from '../../data/news/newsItems.json';

const HomeNews = () => {
    function findNewsForMainPage() {
        return HomeItems
            .filter(n => n.mainPage != null)
            .sort((a,b) => a.mainPage > b.mainPage)
            .slice(0,3);
    }

    const HomeNewsItems = findNewsForMainPage();
    return (
        <section id="co-nowego" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>Informacje dla Pacjentów Przychodni</h2>
                </div>

                <div className="blog-shortcode">
                    <div className="row gutter-width-md with-pb-md">
                        { HomeNewsItems && HomeNewsItems.map( ( item, key ) => {
                            return(
                                <div key={ key } className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="card card-post">
                                        <div className="card-top position-relative">
                                            <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>
                                                <div className="img object-fit overflow-hidden">
                                                    <div className="object-fit-cover transform-scale-h">
                                                        <img className="card-top-img" src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>
                                            </a>
        
                                            <div className="post-date">
                                                <div className="post-date-0">
                                                    <p><span className="post-date-number">{ moment(item.date).format('DD') }</span>{ moment(item.date).format('MMM') }</p>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                                            </h5>
        
                                            <p className="card-text">{ item.description }</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="spacer m-top-lg text-right">
                    <a title="Zobacz więcej" className="btn btn-primary" href={ process.env.PUBLIC_URL + "/co-nowego/" }>Zobacz więcej</a>
                </div>
            </div>
        </section>
    );
};

export default HomeNews;
