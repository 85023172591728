import React from 'react';
import { Link } from 'react-scroll';

const HomeAboutText = () => {
    return (
        <div id="about-text" className="wrapper">
            <div className="title text-max-width">
                <h2>O nas</h2>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Twoje Zdrowie w Dobrych Rękach</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Witamy w Przychodni na Pańskiej, miejscu, w którym indywidualne podejście do pacjenta i najwyższa jakość opieki stomatologicznej idą w parze z przyjazną atmosferą. Nasza misja to zapewnienie każdemu pacjentowi kompleksowej i skutecznej opieki w komfortowym i przyjaznym otoczeniu.</p>
                    </div>
                </div>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Doświadczony Zespół Specjalistów</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Nasz zespół to profesjonaliści, którzy nieustannie dbają o rozwijanie swoich kompetencji, uczestnicząc w szkoleniach i warsztatach, aby być na bieżąco z najnowszymi trendami w medycynie dentystycznej. Jesteśmy w trakcie intensywnego rozwoju i w najbliższym czasie nasz zespół zostanie wzmocniony przez kolejnych specjalistów, co pozwoli nam na rozszerzenie oferty usług i zminimalizowanie czasu oczekiwania na wizyty.</p>
                    </div>
                </div>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Zakres Naszych Usług</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Oferujemy szeroki zakres usług stomatologicznych, od stomatologii zachowawczej i dziecięcej, przez stomatologię estetyczną, aż po chirurgię stomatologiczną. Niezależnie od Twoich potrzeb, z pewnością znajdziesz u nas kompleksowe rozwiązania dla swojego zdrowia jamy ustnej.</p>
                    </div>
                </div>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Indywidualne Podejście i Przyjazna Atmosfera</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>W Przychodni na Pańskiej każdy pacjent jest traktowany indywidualnie. Zdajemy sobie sprawę, że każda osoba ma różne potrzeby i oczekiwania, dlatego dostosowujemy naszą opiekę do indywidualnych preferencji i możliwości pacjentów.</p>
                    </div>
                </div>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Nowoczesna Technologia</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Nasz gabinet wyposażony jest w najnowsze technologie i sprzęt medyczny, co pozwala nam na świadczenie usług na najwyższym poziomie. Zastosowanie nowoczesnych rozwiązań gwarantuje skuteczność leczenia i komfort pacjenta.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h3>Dlaczego My?</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Wybierając Przychodnię na Pańskiej, zyskujesz pewność, że Twoje zdrowie jest w najlepszych rękach. Dbamy o każdy detal – od pierwszego kontaktu z recepcją, przez profesjonalną diagnozę i leczenie, aż po indywidualne podejście i opiekę po wizycie.</p>
                    </div>

                    <div className="button spacer p-top-lg">
                        <Link to="cennik" href="#cennik" className="btn btn-primary scroll-to-id">Cennik</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutText;
